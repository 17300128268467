import {
    Avatar,
    Button,
    Col,
    Modal,
    Row,
    Spin,
    Tabs,
    Typography,
    Upload,
    message,
} from "antd"
import React, { useEffect, useRef, useState } from "react"
import MainLayout from "../../components/main_layout/MainLayout"
import "./settings.css"
import Profile from "../../components/setting_sections/Profile"
import AdvanceSettings from "../../components/setting_sections/AdvanceSettings"
import {
    useAdminGetMeQuery,
    useAdminLogoUpdateMutation,
} from "../../features/api/adminApi/adminSetting"
import errorMessage from "../../global/errorMessage/ErrorMessage"
import { CiUser } from "react-icons/ci"
import CustomerCheckInformation from "../../components/customer_sections/customer_checkin_info"
import { profileUpload } from "../../global/helpers"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../features/slice/authSlice"


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

const Settings = () => {
    //Hooks
    const imageRef = useRef()
    const dispatch = useDispatch()
    const authSliceData = useSelector((state) => state.auth)
    const [profile, setProfile] = useState(null)
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState("")
    const [previewTitle, setPreviewTitle] = useState("")

    const [image, setImage] = useState()
    const [fileList, setFileList] = useState([])


    //states
    const [key, setKey] = useState(1)
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    })
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        })
    }

    //breadcrumbs
    const breadcrumbs = [
        {
            title: "Dashboard",
            href: "/admin/dashboard",
        },
        {
            title: "Settings",
            // href: location.pathname,
        },
    ]

    const {
        data: profileData,
        isFetching: profileFetching,
        isLoading: profileLoading,
        refetch,
    } = useAdminGetMeQuery()

    const [logoUpdate] = useAdminLogoUpdateMutation()


    const handleCancel = () => setPreviewOpen(false)
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1))
    }
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)

    useEffect(() => {
        window.addEventListener("resize", setDimension)

        return () => {
            window.removeEventListener("resize", setDimension)
        }
    }, [screenSize])

    useEffect(() => {
        if (profileData) {

            //Store current value to Auth slice
            const currentSliceData = { 
                ...authSliceData,
                 ['user']: profileData?.data
            }
            dispatch(setUser(currentSliceData))
            setProfile(profileData.data)
            setImage(`${process.env.REACT_APP_IMG_URL}/${profileData.data.logo}`)
            const url = profileData.data.logo ? `${process.env.REACT_APP_IMG_URL}/${profileData.data.logo}` : null
            if (url) {
                setFileList([{
                    uid: url,
                    status: 'done',
                    url,
                    name: url.substring(url.lastIndexOf('/') + 1)
                }])
            }

        }
    }, [profileData])

    return (
        <Spin spinning={false}>
            <MainLayout title={"Settings"} breadcrumbs={breadcrumbs} upload={[]} button={[]}>
                <Row className="wrapper" gutter={[30, 20]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                        <Row
                            gutter={[20, 20]}
                            style={{
                                paddingTop: "20px",
                            }}>
                            <Col
                                span={24}
                                style={{
                                    marginLeft: screenSize.dynamicWidth <= 1200 && "30px",
                                }}>
                                {/* <Avatar
                                    className="user_icon"
                                    icon={
                                        <Image
                                            width={100}
                                            height={100}
                                            shape="circle"
                                            src={image}
                                        />
                                    }
                                /> */}
                                <>
                                <div className="upload-center">
                                    <Upload
                                        customRequest={async (options) => {
                                            const { file, onSuccess, onError } = options
                                            try {
                                                const formData = new FormData()
                                                formData.append("logo", file)
                                                const result = await logoUpdate({ url: "update", formData }).unwrap()
                                                message.success(result.message)
                                                onSuccess('Ok')
                                            } catch (error) {
                                                errorMessage(error.data.error)
                                                onError("error")
                                            }
                                        }}
                                        beforeUpload={profileUpload}
                                        accept=".jpeg,.jpg,.png"
                                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-circle"
                                        maxCount={1}
                                        fileList={fileList}
                                        onRemove={async (options) => {
                                            const { file, onSuccess, onError } = options
                                            try {
                                                const formData = new FormData()
                                                formData.append("logo", file)
                                                const result = await logoUpdate({ url: "delete", formData }).unwrap()
                                                message.success(result.message)
                                                setFileList([])
                                                onSuccess('Ok')
                                            } catch (error) {
                                                errorMessage(error.data.error)
                                                onError("error")
                                            }
                                        }}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                    >
                                    {
                                        fileList.length < 1 ? <>
                                            <Button ref={imageRef} className="d-none"></Button>
                                            <Avatar icon={<CiUser />} className="layout_top_right_avatar" />  </> : null
                                    }
                                    </Upload>
                                        {
                                            fileList.length < 1 ? <Typography.Text
                                                onClick={() => {
                                                    imageRef.current.click()
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    textAlign: "center",
                                                    textDecoration: "underline",
                                                }}>
                                                Upload Logo
                                            </Typography.Text> : null
                                        }
                                </div>
                                   
                                    <Modal
                                        open={previewOpen}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={handleCancel}>
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    </Modal>
                                </>
                            </Col>
                            <Col className="tabCenterPlace">
                                <Tabs
                                    tabPosition={screenSize.dynamicWidth <= 1200 ? "top" : "left"}
                                    defaultActiveKey={1}
                                    activeKey={key}
                                    onChange={(key) => {
                                        setKey(key)
                                    }}
                                    items={[
                                        {
                                            label: "Profile",
                                            key: 1,
                                        },
                                        {
                                            label: "Account Settings",
                                            key: 2,
                                        },
                                        {
                                            label: "Check-In Information",
                                            key: 3,
                                        },
                                    ]}></Tabs>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                        {key === 1 ? (
                            <>
                                {
                                    <Profile
                                        setFileList={setFileList}
                                        fileList={fileList}
                                        setImage={setImage}
                                        refetch={refetch}
                                        profileData={profile}
                                        loading={
                                            profileFetching === true || profileLoading === true
                                                ? true
                                                : false
                                        }
                                    />
                                }
                            </>
                        ) : key === 2 ? (
                            <AdvanceSettings />
                        ) : key === 3 ? (
                            <CustomerCheckInformation
                                customerData={profileData?.data}
                                customerLoading={profileLoading}
                                customerFetching={profileFetching}
                            />
                        ) : null}
                    </Col>
                </Row>
            </MainLayout>
        </Spin>
    )
}

export default Settings
