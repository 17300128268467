import CryptoJS from "crypto-js";
import errorAlert from "./errorMessage/ErrorMessage";
import { Upload, message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import { TbMessage2 } from "react-icons/tb";
import errorMessage from "./errorMessage/ErrorMessage";
import { useDispatch } from "react-redux";
import { mainApi } from "../features/api/mainApi";
import { useNavigation } from "react-router-dom";
import { isEmpty, isNull, isUndefined } from "lodash"
dayjs.extend(utc);
dayjs.extend(timezone);

const key = process.env.REACT_APP_SECRET_KEY;

export const decrypt = (payload) => {
	let encryptStr = CryptoJS.enc.Base64.parse(payload);
	let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
	encryptData = JSON.parse(encryptData);
	let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
	var decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Base64.parse(key), {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	return CryptoJS.enc.Utf8.stringify(decrypted);
};

export const beforeUpload = (file) => {
	const acceptedFormats = [
		"pdf",
		"jpeg",
		"jpg",
		"png",
		"vnd.ms-excel",
		"vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	];
	if (acceptedFormats.includes(file.type.split("/")[1])) {
		if (file.size / 1024 / 1024 < 1) {
			return true;
		} else {
			message.error("File must be smaller than 1MB");
			return Upload.LIST_IGNORE;
		}
	} else {
		message.error("You can only upload PDF, JPEG, PNG, JPG and XLS files!");
		return Upload.LIST_IGNORE;
	}
};
export const profileUpload = (file) => {
	const acceptedFormats = ["jpeg", "jpg", "png"];
	if (acceptedFormats.includes(file.type.split("/")[1])) {
		// message.error("You can only upload PDF, JPEG, PNG, JPG and XLS files!")
		// if (file.size / 1024 / 1024 < 1) {
		//     return true
		// } else {
		//     message.error("File must be smaller than 1MB")
		//     return Upload.LIST_IGNORE
		// }
		return true;
	} else {
		message.error("You can only upload  JPEG, PNG and JPG  files!");
		return Upload.LIST_IGNORE;
	}
};

export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
		return reader;
	});

export const phoneFormatNumber = (value) => {
	if (!value) return "";
	const numStr = value.toString();
	const numLen = numStr.length;
	if (numLen <= 1) return `+${numStr}`;
	if (numLen <= 4) return `+${numStr.substring(0, 1)} ${numStr.substring(1)}`;
	let formattedNum = `+${numStr.substring(0, 1)} `;
	for (let i = 1, j = 2; i < numLen; i++, j++) {
		formattedNum += numStr.charAt(i);
		if (j % 3 === 0 && i !== numLen - 1) formattedNum += " ";
	}
	return formattedNum;
};

export const phoneParseNumber = (value) => {
	const numStr = value.toString().replace(/\s/g, "").substring(1); // remove spaces and +
	return numStr ? parseInt(numStr, 10) : 0;
};

export const sinFormatNumber = (value) => {
	if (!value) return "";
	const numStr = value.toString();
	const numLen = numStr.length;
	if (numLen <= 3) return numStr;
	let formattedNum = "";
	for (let i = numLen - 1, j = 1; i >= 0; i--, j++) {
		formattedNum = numStr.charAt(i) + formattedNum;
		if (j % 3 === 0 && i !== 0) formattedNum = " " + formattedNum;
	}
	return formattedNum;
};

export const sinParseNumber = (value) => {
	const numStr = value.toString().replace(/\s/g, ""); // remove spaces
	return numStr ? parseInt(numStr, 10) : 0;
};

export const formatNumber = (value) => {
	if (!value) return "";
	const timeStr = value.toString();
	const hours = timeStr.substring(0, 2).padStart(0, "0");
	const minutes = timeStr.substring(2);
	return `${hours}:${minutes}`;
};

export const parseNumber = (value) => {
	const numberStr = value.toString().replace(" ", "");
	const firstPart = parseInt(numberStr, 10);
	const secondPart = parseInt(numberStr, 10);
	return numberStr;
};

export function disabledDate(current) {
	const now = dayjs();
	return current && current.isAfter(now, "day");
}

export function enableDate(current) {
	const now = dayjs();
	return current && current.isBefore(now, "day");
}

export function disabledYear(current) {
	const now = dayjs();
	return current && current.isAfter(now, "year");
}
export function _handleDisabledDateAndTime(current, disabledType = "day") {
	const now = dayjs();
	return current && current.isAfter(now, disabledType);
}

export const restrictAlphabets = (evt) => {
	if ((evt.which != 8 && evt.which != 0 && evt.which < 48) || evt.which > 57) {
		evt.preventDefault();
	}
};

export function autoFormatPhoneNumber(phoneNumberString) {
	try {
		var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
		var match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/);
		var intlCode = "+1 ";
		return [
			intlCode,
			match[2],
			match[2] ? " " : "",
			// match[3] ? ") " : "",
			match[3],
			match[4] ? " " : "",
			match[4],
		].join("");
	} catch (err) {
		return phoneNumberString;
	}
}

export function autoTimeFormat(timeString) {
	try {
		var cleaned = ("" + timeString).replace(/\D/g, "");
		var match = cleaned.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
		// var intlCode = "+1 "
		return [
			// intlCode,
			match[2],
			match[2] ? " " : "",
			// match[3] ? ") " : "",
			match[3],
			match[4] ? " " : "",
			match[4],
		].join("");
	} catch (err) {
		return timeString;
	}
}
export function formatDate(date, format) {
	return date ? dayjs(date, format).format(format) : null;
}
export const handleOverTime = (e, limit, form, name) => {
	const { value } = e.target;
	if (value.split(":")[0] >= limit) {
		if (name === "overtime_per_day") {
			form.setFieldValue(name, `0${limit}:00`);
		} else {
			form.setFieldValue(name, `${limit}:00`);
		}
	}
};
export const handlePaymentTime = (record) => {
	let from_date;
	let to_date;
	if (record.payment_type === "day") {
		from_date = dayjs().format("YYYY-MM-DD"); // setPaymentTypeDate(dayjs())
		to_date = dayjs().format("YYYY-MM-DD");
		// handlePaymentType(from_date, to_date)
		// setPaymentTypeDate(dayjs())
		// setEndDate(dayjs())
	} else if (record.payment_type === "month" || record.payment_type === "monthly") {
		from_date = dayjs().startOf("month");
		to_date = dayjs().endOf("month");
		// handlePaymentType(from_date, to_date)
		// setStartDate(dayjs().startOf("month"))
		// setEndDate(dayjs().endOf("month"))
	} else if (record.payment_type === "week" || record.payment_type === "weekly") {
		from_date = dayjs().startOf("week");
		to_date = dayjs().endOf("week");
		// handlePaymentType(from_date, to_date)
		// setStartDate(dayjs().startOf("week"))
		// setEndDate(dayjs().endOf("week"))
	} else {
		if (dayjs().date() <= 15) {
			from_date = dayjs().startOf("month");
			to_date = dayjs().set("date", 15);
			// handlePaymentType(from_date, to_date)
			// setStartDate(dayjs().startOf("month"))
			// setEndDate(dayjs().set("date", 15))
		} else {
			from_date = dayjs().set("date", 16);
			to_date = dayjs().set("date", dayjs().daysInMonth());
			// handlePaymentType(from_date, to_date)
			// setStartDate(dayjs().set("date", 16))
			// setEndDate(dayjs().set('date', dayjs().daysInMonth()))
		}
		// }
	}
	return { from_date, to_date };
};
export const handleTimeFormat = (e, limit, form, name) => {
	const { value } = e.target;
	if (value.split(":")[0] > limit) {
		form.setFieldValue(name, `${limit}:00`);
	}
};

export function urlToFile(doc) {
	return {
		id: doc?.id,
		name: doc?.document?.substring(doc?.document.lastIndexOf("/") + 1),
		status: "done",
		url: `${process.env.REACT_APP_IMG_URL}/${doc.document}`,
	};
}

export const Utctime = (time = dayjs(), zone = "America/New_York") => {
	return dayjs(time).utc().tz(zone);
};

export const HandleTotalHours = (record, time) => {
	const {
		server_time,
		date,
		actual_time_in,
		record_time_in,
		in_meridiem,
		company_break_time,
		total_hours,
		system_total_hours,
		status,
	} = record;
	if (status === "Check IN") {
		// const utc_server_time = dayjs(server_time, "YYYY-MM-DD hh:mm:ss A");
		// const utc_time = dayjs(`${date} ${record_time_in}`, "YYYY-MM-DD hh:mm A");
		// // const utc_time = dayjs(`${date} ${actual_time_in} ${in_meridiem}`, "YYYY-MM-DD hh:mm A");
		// const mins = dayjs(utc_server_time).diff(utc_time, "minutes");
		// const totalHours =
		// 	parseInt(mins / 60) >= company_break_time
		// 		? parseInt(mins / 60) - parseInt(company_break_time)
		// 		: parseInt(mins / 60);
		// const totalMins = dayjs(utc_server_time).minute(mins).$m;
		// const totalHour = totalHours <= 9 ? `0${totalHours}` : `${totalHours}`;
		// const totalMin = totalMins <= 9 ? `0${totalMins}` : `${totalMins}`;
		// return `${totalHour}:${totalMin}`;
		return system_total_hours;
	} else {
		return total_hours;
	}
};
export const HandleLunchBreak = (record, time) => {
	const { server_time, date, record_time_in, lunch_break, company_break_time, breaks, status } =
		record;
	if (status === "Check IN") {
		// const utc_server_time = dayjs(server_time, "YYYY-MM-DD hh:mm:ss A");
		// const utc_time = dayjs(`${date} ${record_time_in}`, "YYYY-MM-DD hh:mm A");
		// // const utc_time = dayjs(`${date} ${actual_time_in} ${in_meridiem}`, "YYYY-MM-DD hh:mm A");
		// const mins = dayjs(utc_server_time).diff(utc_time, "minutes");
		// // if (mins >= 270) {
		// // 	return "30 Min";
		// // } else {
		// // 	return "0 Min";
		// // }
		// if (mins >= company_break_time) {
		// 	return company_break_time ? `${company_break_time} Min` : "0 Min";
		// } else {
		// 	return "0 Min";
		// }break
		return breaks ? `${breaks} Min` : "0 Min";
	} else {
		return lunch_break ? `${lunch_break} Min` : "0 Min";
	}
};

export const ToastMessage = (message, type, errors) => {
	if (type === "error") {
		for (const key in errors) {
			// if (Object.prototype.hasOwnProperty.call(errors, key)) {
			const errorMsg = errors[key];
			if (Array.isArray(errorMsg)) {
				toast.error(errorMsg[0]);
				
			} else {
				toast.error(errorMsg);
			}
			// break;
			// }
		}
	} else if (type === "info") {
		return toast.info(message);
	} else {
		return toast.success(message);
	}
};

export const ToastBoxMessage = (message, type, errors) => {
	if (type === "error") {
		for (const key in errors) {
			// if (Object.prototype.hasOwnProperty.call(errors, key)) {
			const errorMsg = errors[key];
			if (Array.isArray(errorMsg)) {
				return errorMsg[0];
				
			} else {
				return errorMsg;
			}
			// break;
			// }
		}
	} else if (type === "info") {
		return message;
	} else {
		return message;
	}
};

export const selectOptions = (array, name) => {
	return name === "business_types"
		? array?.map((item, index) => {
				return {
					label: "",
					value: "",
					key: index,
				};
		  })
		: array.map((item, index) => {
				return {
					label: "",
					value: "",
					key: index,
				};
		  });
};

export const _handleCheckIn = (event) => {
	var regex = new RegExp("^[0-9]+$");
	var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
	if (!regex.test(key)) {
		event.preventDefault();
		return false;
	} else {
		return true;
	}
};
export const _handleEditNavigation = (navigate, path = "") => {
	navigate(path);
};
export const _handleEmployeeId = (event) => {
	var regex = new RegExp("^[a-zA-Z0-9]+$");
	var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
	if (!regex.test(key)) {
		event.preventDefault();
		return false;
	}
};
export const _handleRate = (evt) => {
	if (evt.which !== 46) {
		if ((evt.which != 8 && evt.which != 0 && evt.which < 48) || evt.which > 57) {
			evt.preventDefault();
		}
	}
};

export const _handleRateBlur = (e, form) => {
	const { name, value } = e.target;
	const split = value.split(".");
	if (value !== "") {
		if (split.length < 2) {
			const newValue = value + ".00";
			form.setFieldValue("rate", newValue);
		} else if (split.length >= 2) {
			form.setFieldValue("rate", value);
		}
	} else {
		if (split.length < 2) {
			form.setFieldValue("rate", "0.00");
		} else {
			form.setFieldValue("rate", value);
		}
	}
};

export function makeQueryString(q) {
    let params = new URLSearchParams(q);
    let keysForDel = [];
	params.forEach((value, key) => {
		if (isEmpty(value) || isNull(value) || isUndefined(value)) {
			keysForDel.push(key);
		}
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });

    //console.log(params.toString());
    return params.toString();
}
